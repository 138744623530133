/*
 * In this file, you may override the settings in bootstrap by specifying your
 * own definitions.
 *
 * See bootstrap's `scss/_variables.scss` for a list of modifiable varaibles.
 */
$primary: #057dcd;
$secondary: #1e3d58;
$light: #f8f2f0;
$dark: #060315;

$font-family-base: 'Inter', sans-serif;

$headings-font-family: 'Roboto', sans-serif;

$body-color: #555555;

$headings-color: $dark;

$headings-font-weight: 700;

$display-font-weight: 700;

$enable-responsive-font-sizes: true;

$border-radius: 0px;

$link-decoration: none;

$enable-negative-margins: true;
